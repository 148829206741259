<template>
  <div class="vip-layout-background" :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : 'px-2'">
    <router-view></router-view>
  </div>
</template>

<script>
import { uiHelper, locale } from '@/util'
export default {
  name: 'vipLayout',
  // metaInfo:{
  //   link:[
  //     {
  //       rel:'canonical', href:'https://jaya9.co/jayarank/challenge/'
  //     }
  //   ]
  //
  // },
  data: () => ({}),
  created() {
    this.metaTag()
  },
  methods: {
    metaTag() {
      uiHelper.setMetaTag('url', window.location.origin + '/jayarank/challenge/')
      uiHelper.setTitle(`${locale.getMessage('meta.challenge_title')}`)
      uiHelper.setMetaTag('title', `${locale.getMessage('meta.challenge_title')}`)
      uiHelper.setMetaTag('description', `${locale.getMessage('meta.challenge_description')}`)
      uiHelper.setMetaTag('keywords', `${locale.getMessage('meta.challenge_keyword')}`)
      uiHelper.setCanonical(window.locatin.origin + '/jayarank/challenge/')
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    }
  }
}
</script>

<style lang="scss">
.vip-layout-background {
  width: 100%;
  height: 100%;
  /*background: url("/static/image/GrabCoins/icon/bj.png") no-repeat top left;*/
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  margin: 0 auto;
  background-size: 100%;
  background: url('/static/image/background/kk-vip-background.jpeg');
  background-size: cover;
}
</style>
